<template>
    <div class="device-manage-page">
        <div class="filter-bar-box">
            <el-form  ref="form" :model="filter" label-width="60px" inline>
                <el-form-item prop="status" label="状态">
                    <el-select v-model="filter.status" placeholder="请选择状态" size="small">
                        <el-option label="待审核" value="0"></el-option>
                        <el-option label="已通过" value="1"></el-option>
                        <el-option label="未通过" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="deviceNo" label="设备号">
                      <el-input v-model="filter.deviceNo" size="small" placeholder="请输入设备号"></el-input>
                </el-form-item>
                <el-form-item prop="phoneNo" label="手机号">
                      <el-input v-model="filter.phoneNo" size="small" placeholder="请输入手机号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="danger" @click="getDeviceBindList" size="small">查 询</el-button>
                    <el-button size="small" @click="resetForm">重 置</el-button>
                </el-form-item>
            </el-form>
        </div>
           <main class="main">
            <el-table :data="tableData" style="width: 100%" size="small">
                <el-table-column
                    type="index"
                    label="序号"
                    align="center"
                    >
                </el-table-column>
                <el-table-column
                    prop="equipCode"
                    label="设备号"
                    align="center"
                    >
                </el-table-column>
                <el-table-column
                    prop="equipName"
                    label="设备名"
                    align="center"
                    >
                </el-table-column>
                <el-table-column
                    prop="equipType"
                    label="设备类型"
                    align="center"
                    >
                </el-table-column>
                <el-table-column
                    prop="createTime"
                    label="添加时间"
                    align="center"
                    >
                </el-table-column>
                <el-table-column
                    prop="dataCost"
                    label="本月直播流量消耗"
                    align="center"
                    width="160"
                    >
                </el-table-column>
                <el-table-column
                    prop="phoneNo"
                    label="客户电话"
                    align="center"
                    width="160"
                    >
                </el-table-column>
                <el-table-column
                    prop="auditStatus"
                    label="审核状态"
                    align="center"
                    width="160"
                    >
                    <template slot-scope="scope">
                        <span>{{formatStatus(scope.row)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="address"
                    label="操作"
                    align="center"
                    width="340"
                    >
                    <template slot-scope="scope">
                        <el-button @click.native.prevent="overDetails(scope.row)" type="info" size="mini">查看</el-button>
                        <el-button @click.native.prevent="handleAuditStatus(scope.row, 1)" type="primary" size="mini" v-if="scope.row.auditStatus == '2' || scope.row.auditStatus == '0'">通过</el-button>
                        <el-button @click.native.prevent="handleAuditStatus(scope.row, 2)" type="danger" size="mini" v-if="scope.row.auditStatus == '0'">不通过</el-button>
                        <el-button @click.native.prevent="handleAuditStatus(scope.row, 2)" type="warning" size="mini" v-if="scope.row.auditStatus == '1'">取消通过</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination-box mt10">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageNo"
                    :page-sizes="[10, 20, 40, 100]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageTotal">
                </el-pagination>
            </div>
        </main>

        <el-dialog
            title="提示"
            :visible.sync="centerDialogVisible"
            width="30%"
            center>
            <div>
                <div class="dialog-line-item">
                    <div>设备铭牌:</div>
                    <img :src="equipDetails.thumbnailUrl" />
                </div>
                <div class="dialog-line-item">
                    <div>电话：</div>
                    <span>{{equipDetails.phoneNo}}</span>
                </div>
                <div class="dialog-line-item">
                    <div>设备编号：</div>
                    <span>{{equipDetails.equipCode}}</span>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="handleAuditStatus(equipDetails, 1)" v-if="equipDetails.auditStatus == '2' || equipDetails.auditStatus == '0'">通 过</el-button>
                <el-button @click="handleAuditStatus(equipDetails, 2)" type="danger" size="mini" v-if="equipDetails.auditStatus == '0'">不通过</el-button>
                <el-button type="warning" @click="handleAuditStatus(equipDetails, 2)" v-if="equipDetails.auditStatus == '1'">取消通过</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import { getDeviceBindList, updateDeviceStatus } from '@/services'
export default {
    data() {
        return {
            centerDialogVisible: false,
            filter: {
                status: '',
                deviceNo: '',
                phoneNo: ''
            },
            auditStatusMap: {
                '0': '待审核',
                '1': '已通过',
                '2': '未通过',
                '3': '已删除',
            },
            tableData: [],
            pageSize: 10,
            pageNo: 1,
            pageTotal: 0,
            equipDetails: {}
        }
    },

    created() {
        this.getDeviceBindList()
    },

    methods: {
        getDeviceBindList() {
            getDeviceBindList({
                ...this.filter,
                pageNo: this.pageNo,
                pageSize: this.pageSize
            }).then(res => {
                if(res.code == 200) {
                    let content = res.content || {}
                    this.tableData = []
                    content.records.forEach(item => {
                        if(item) {
                            this.tableData.push(item)
                        }
                    })
                    this.pageTotal = content.total
                } else {
                    this.$message.error(res.message)
                }
            })
        },

        /**
         * 更新状态
         * @author tanguozheng
         * @date 2021-12-06 14:30
         */
        updateDeviceStatus(equipCode, auditStatus) {
            updateDeviceStatus({
                equipCode: equipCode,
                auditStatus: auditStatus
            }).then(res => {
                 if(res.code == 200) {
                    this.getDeviceBindList()
                    this.$message.success('修改成功')
                } else {
                    this.$message.error(res.message)
                }
            })
        },
          /**
         * 修改每页显示条数
         * @author tanguozheng
         * @date 2021-07-12 19:15
         * @param {*}
         * @returns
         */
        handleSizeChange(size) {
            this.pageNo = 1
            this.pageSize = size
            this.getDeviceBindList()
        },

        /**
         * 翻页
         * @author tanguozheng
         * @date 2021-07-12 19:15
         * @param {*}
         * @returns
         */
        handleCurrentChange(currentPage) {
            this.pageNo = currentPage
            this.getDeviceBindList()
        },
        
        //重置
        resetForm() {
            this.$refs['form'].resetFields();
        },

        /**
         * 点击查看
         * @author tanguozheng
         * @date 2021-12-06 14:29
         * @param {*}
         * @returns
         */
        overDetails(row) {
            this.centerDialogVisible = true
            this.equipDetails = row
        },

        /**
         * 修改设备状态
         * @author tanguozheng
         * @date 2021-12-06 14:50
         */
        handleAuditStatus(row, status) {
            let equipCode = row.equipCode
            this.updateDeviceStatus(equipCode, status)
            this.centerDialogVisible = false
        },

        formatStatus(row) {
            if(!row) return ''
            return this.auditStatusMap[row.auditStatus]
        }
    }

}
</script>

<style lang="scss">
.device-manage-page{
    padding: 20px;
    background-color: #f0f2f5;
    display: flex;
    flex-direction: column;

    .filter-bar-box{
        padding-top: 22px;
        background-color: #ffffff;
    }

    .main {
        background-color: #ffffff;
        margin-top: 22px;
        flex: 1;
        padding: 15px;

        .pagination-box{
            text-align: right;
        }
    }

}
.dialog-line-item {
    display: flex;
    margin-top: 20px;
    >div{
        width: 80px;
        text-align: right;
        margin-right: 20px;
    }

    img{
        width: 65%;
        height: 168px;
    }
}
</style>

